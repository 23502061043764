<template>
  <!-- 机票的票面 -->
  <header>
    <div class="headInfo head-background">
      <van-row type="flex" justify="space-between" class="line1">
        <van-col>{{aircraftTicket.departDate|comverTime("YYYY-MM-DD")}}</van-col>
        <van-col>{{aircraftTicket.runTime}}</van-col>
        <van-col>{{aircraftTicket.arriveDate}}</van-col>
      </van-row>
      <van-row type="flex" justify="space-between" class="line2">
        <van-col>
          <h3 span="8">{{aircraftTicket.departTime}}</h3>
        </van-col>
        <van-col>
          <h3 span="8">
            <span class="icon iconfont icon-icon_jipiaodancheng"></span>
          </h3>
        </van-col>
        <van-col>
          <h3 span="8">{{aircraftTicket.arriveTime}}</h3>
        </van-col>
      </van-row>
      <van-row type="flex" justify="space-between" class="line1">
        <van-col
          span="12"
          class="van-ellipsis left"
        >{{aircraftTicket.departAirport||aircraftTicket.departAirPort}}</van-col>
        <van-col class="cen">{{aircraftTicket.flightNo}}</van-col>
        <van-col
          span="12"
          class="van-ellipsis right"
        >{{aircraftTicket.arriveAirport||aircraftTicket.arriveAirPort}}</van-col>
      </van-row>
    </div>
  </header>
</template>

<script>
export default {
  name: "ordertype",
  props: {
    aircraftTicket: {
      default: {
        departAirport: "",
        departDate: "",
        departTime: "",
        runTime: "",
        arriveAirport: "",
        arriveDate: "",
        arriveTime: "",
        flightNo: ""
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" src="@/styles/orderHeadCommon.less" scoped></style>
<style lang="less" scoped>
</style>